import { Link, graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = ({ data }) => (
  <Layout lightNav={true}>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div className="pb-20 pt-16 bg-white hero-image">
      <div className="relative">
        <div className="container page body-content">
          <h1 className="hero-title text-white">
            <span className="pr-1">Exacting</span> Jersey Restoration.
            <br />
            Original Creations.
          </h1>
          <div className="hero-divider" />
          <div className="lg:max-w-md mb-12 md:mb-20">
            <p className="hero-paragraph text-white">
              The Dream Shop is uniquely qualified to restore or customize your
              MLB jersey. With an in-house reference of over 1000 team-issued
              jerseys and close to one million photos in our library, I have
              recreated nearly every team pattern so that, when finished, your
              jersey will look exactly as your team wore it on the field.
            </p>
            <p className="hero-paragraph text-white">
              Our website is still under construction, but in the meantime, you
              can find answers to the most common questions as well as links on
              how to get started below.
            </p>
          </div>
          <Link to="/get-started" className="button button-purple">
            Get Started
          </Link>
        </div>
      </div>
    </div>
    <div className="container page py-12 md:py-16 lg:py-32 lg:max-w-lg">
      <h2 className="section-header mb-0 lg:text-center">
        Frequently Asked Questions
      </h2>
      <div className="faq-item">
        <h3 className="faq-question">What kinds of services do you offer?</h3>
        <div className="faq-answer">
          <p>
            I specialize in the team jerseys of Major League Baseball—it's what
            I do. If you have something else (for example, Minor League Baseball
            or basketball), please ask. While these are not my specially, I have
            done a few.
            <br /> <br />I offer the following services:
          </p>
          <ul>
            <li>Restoration / Conservation</li>
            <li>Customization</li>
            <li>Recreation</li>
            <li>Authentication</li>
          </ul>
        </div>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">
          Who are you and why should I work with you?
        </h3>
        <p className="faq-answer">
          Learn more <Link to="/about">about me</Link> and my expertise.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">What is a “restoration?”</h3>
        <p className="faq-answer">
          For decades, MLB teams sent their old jerseys to Spring Training and
          to the Minor Leagues for reuse, where player names and numbers were
          changed. Sometimes even the front scripts were removed.
          <br />
          <br />
          If you want your repurposed jersey returned to its original
          appearance, I am qualified to do that. My goal is proper restoration
          and conservation of these artifacts to your specifications, repairing
          damage and misuse, restoring the lettering to is original MLB
          appearance. Using archival photos and original team-issued jerseys
          from my massive collection, we can make old look almost new again.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">What counts as customization?</h3>
        <p className="faq-answer">
          My expertise starts with a thorough knowledge of every team's specific
          details—including the correct fonts, sizes, colors, and techniques—and
          translating that into ensuring your jersey will look just as it would
          have been team-lettered in its year of issue.
          <br />
          <br />
          Through painstaking research, I have recreated the team fonts and the
          hundreds of custom cutting patterns needed to customize jerseys for
          every team, every jersey style and every year back to the early 1970s.
          <br />
          <br />
          I strive for accuracy in every detail: knowing whether your jersey
          gets a name on back or not, a nameplate, the proper arch of the player
          name, the proper size and placement of front and back numbers, the
          right colors of twill and correct outline thicknesses, to name a few
          details.
          <br />
          <br />
          And at no extra charge, I will always send you a proof for your
          approval before I sew a thing, a unique service.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">What counts as a recreation?</h3>
        <p className="faq-answer">
          Is owning a real 1969 Seattle Pilots jersey on your wish list but out
          of your price range?
          <br />
          <br />
          Have you always wanted a 1958 Cleveland Indians road jersey but no one
          makes a reproduction?
          <br />
          <br />
          Have you always been amazed at the detail in a 1968 Oakland Athletics
          jersey but never dared hope to own a real one?
          <br />
          <br />
          Do you want to make an exact replica of your father's high school
          baseball jersey?
          <br />
          <br />I can help you make these dreams come true, creating one of a
          kind jerseys that people will stop you and ask, “Where did you get
          that?” We'll need to find a blank jersey or one that we can strip the
          lettering from to get started. I can recreate team scripts and old
          lettering from tackle twill or felt and sew them to resemble the
          originals.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">What can you authenticate?</h3>
        <p className="faq-answer">
          My decades of researching and documenting team issued MLB Jerseys has
          qualified me to serve as an authenticator for many of the largest
          auction houses in the industry. I can help you protect your investment
          by evaluating your jerseys.
          <br />
          <br />
          To make it easy to get started, I have a “quick look” rate where,
          after a cursory evaluation, I will tell you if your item warrants a
          deeper look and further evaluation and perhaps a full authentication.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">
          Can I see examples of previous work you've done?
        </h3>
        <p className="faq-answer">
          Absolutely.{' '}
          <a
            href="https://wfhenderson.smugmug.com/The-Dream-Shop-A-Gallery-of-Work"
            target="_blank"
            rel="noopener noreferrer"
          >
            Here’s a gallery
          </a>{' '}
          of a few samples of my work.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">How much does this cost?</h3>
        <p className="faq-answer">
          I am glad to provide you with a detailed estimate, but this should
          help you get started:
          <br />
          <br />
          For jersey customization, pricing is based on the number of elements
          (front number, back number, name on back) and the number of colors for
          each element. There are additional charges for adding a nameplate, and
          sewing patches.
          <br />
          <br />
          Restoration and recreation are quoted estimating the amount of time
          and skill it will take me to do the job correctly, and are generally a
          bit more expensive, but not unreasonably so.
          <br />
          <br />
          Learn more and see examples on the{' '}
          <Link to="/pricing">pricing page</Link>.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">Can I follow you on social media?</h3>
        <p className="faq-answer">
          Yes! I'm on both{' '}
          <a
            href="https://www.facebook.com/gamewornguides/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Facebook
          </a>{' '}
          and{' '}
          <a
            href="https://twitter.com/MLBDreamShop"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          . On Facebook I operate a blog where I showcase examples of my work,
          and we discuss various topics of interest.{' '}
          <a
            href="https://www.facebook.com/gamewornguides/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Check it out
          </a>
          , and please follow me there.
        </p>
      </div>
      <div className="faq-item">
        <h3 className="faq-question">Is there any fine print?</h3>
        <p className="faq-answer">
          My reputation is very important to me. I am keenly aware that the
          accuracy of my work could make it very easy for someone to represent
          my recreations as original and team issued. To ensure that my work is
          never misrepresented, I have a very simple set of rules I follow
          without exception. Read about my{' '}
          <Link to="/fraud-protection">commitment to fraud protection</Link>.
        </p>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    file(name: { eq: "hero" }) {
      childImageSharp {
        fluid(duotone: { highlight: "#f00e2e", shadow: "#192550" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;

export default IndexPage;
